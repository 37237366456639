/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React from 'react';
import HTMLString from 'react-html-string';
import { useStyle } from '../../styles/style';
const LearningExamBody = ({ activeQuestion, question, noOfQuestionsPerSet, handlePreviousClick, handleNextClick, }) => {
    var _a;
    const classes = useStyle();
    return (React.createElement("div", { className: classes.questionBody },
        React.createElement("div", { className: classes.questionBodyHeader },
            React.createElement("div", null,
                "Question No. ",
                activeQuestion)),
        React.createElement("div", { className: classes.questionBodyMain },
            React.createElement("h4", null,
                React.createElement(HTMLString, { html: question === null || question === void 0 ? void 0 : question.questionDescription })),
            React.createElement("div", { className: classes.questionChoices }, (_a = question === null || question === void 0 ? void 0 : question.choices) === null || _a === void 0 ? void 0 : _a.map((choice, index) => (React.createElement("div", { key: choice.choiceID, className: classes.questionLearningChoice },
                React.createElement("div", { className: classes.choiceChar }, "Ans"),
                React.createElement("div", null,
                    React.createElement(HTMLString, { html: choice.choice }))))))),
        React.createElement("div", { className: classes.questionButtons },
            React.createElement(Button, { disableElevation: true, variant: "contained", onClick: handlePreviousClick, disabled: activeQuestion === 1, startIcon: React.createElement(ArrowBackIos, null) }, "PREVIOUS"),
            React.createElement(Stack, { spacing: 2, direction: "row" },
                React.createElement(Button, { disableElevation: true, variant: "contained", onClick: handleNextClick, endIcon: React.createElement(ArrowForwardIos, null), disabled: activeQuestion === noOfQuestionsPerSet }, "NEXT")))));
};
export default LearningExamBody;
